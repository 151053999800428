<template>
	<v-row justify="center">
		<v-dialog v-model="show" max-width="600px">
			<v-card>
				<v-card-title>
					<v-row>
						<v-col>
							<span class="text-h5"
								>Add a social channel to this Brand
							</span>
						</v-col>
					</v-row>
				</v-card-title>

				<v-card-text>
					<v-row>
						<v-col cols="12" sm="4">
							<v-card
								v-if="isFacebookLogin === false"
								class="pa-4"
								outlined
								@click="facebookLogin"
							>
								<v-card-title class="justify-center">
									<v-icon x-large color="blue">
										mdi-facebook
									</v-icon>
									<p class="text-h6 font-weight-light">
										Facebook
									</p>
								</v-card-title>
							</v-card>
							<v-card
								v-else
								class="pa-4"
								outlined
								@click="facebookLogout"
							>
								<v-card-title class="justify-center">
									<v-icon x-large color="blue">
										mdi-facebook
									</v-icon>
									<p class="text-h6 font-weight-light">
										Logout
									</p>
								</v-card-title>
							</v-card>
						</v-col>

						<v-col cols="12" sm="4">
							<v-card
								class="pa-4"
								outlined
								@click="instagramLogin"
							>
								<v-card-title class="justify-center">
									<v-icon x-large> mdi-instagram </v-icon>
									<p class="text-h6 font-weight-light">
										Instagram
									</p>
								</v-card-title>
							</v-card>
						</v-col>

						<v-col cols="12" sm="4">
							<v-card class="pa-4" outlined @click="twitterLogin">
								<v-card-title class="justify-center">
									<v-icon x-large> mdi-twitter </v-icon>
									<p class="text-h6 font-weight-light">
										Twitter
									</p>
								</v-card-title>
							</v-card>
						</v-col>

						<v-col cols="12" sm="4">
							<v-card
								class="pa-4"
								outlined
								@click="linkedinLogin"
							>
								<v-card-title class="justify-center">
									<v-icon x-large> mdi-linkedin </v-icon>
									<p class="text-h6 font-weight-light">
										Linkedin
									</p>
								</v-card-title>
							</v-card>
						</v-col>
					</v-row>
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="blue darken-1"
						text
						@click.stop="show = false"
					>
						Close
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
export default {
	props: {
		value: Boolean,
	},
	computed: {
		show: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
		isFacebookLogin() {
			return this.$store.getters['socialMedia/facebook/getLoginStatus']
		},
		facebookLoginUrl() {
			return this.$store.getters[
				'socialMedia/facebook/getFacebookLoginUrl'
			]
		},
		facebookAccount() {
			return this.$store.getters[
				'socialMedia/facebook/getFacebookAccount'
			]
		},
	},
	methods: {
		// Facebook
		async facebookLogin() {
			window.location.href = this.facebookLoginUrl
		},
		async facebookLogout() {
			this.$store.dispatch('socialMedia/facebook/logout', {
				routeParams: this.$route.params,
				facebookAccount: this.facebookAccount,
			})
		},

		// Instagram
		async instagramLogin() {
			this.$snackbar.notify({ message: 'Coming soon!' })
		},
		async instagramLogout() {
			this.$snackbar.notify({
				message: 'Logged out successfully!',
			})
		},

		// Twitter
		async twitterLogin() {
			this.$snackbar.notify({ message: 'Coming soon!' })
		},
		async twitterLogout() {
			this.$snackbar.notify({
				message: 'Logged out successfully!',
			})
		},

		// Linkedin
		async linkedinLogin() {
			this.$snackbar.notify({ message: 'Coming soon!' })
		},
		async linkedinLogout() {
			this.$snackbar.notify({
				message: 'Logged out successfully!',
			})
		},
	},
}
</script>

<style scoped>
.v-card {
	margin: 0px !important;
}
a {
	text-decoration: none;
}
.material-icons {
	vertical-align: middle;
}
</style>
