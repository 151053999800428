<template>
	<v-container fluid>
		<v-card class="home" outlined>
			<v-row no-gutters class="mb-n1">
				<v-col cols="3">
					<v-row no-gutters>
						<v-col>
							<v-card
								@click.stop="showAddSocialAccountModal = true"
							>
								<v-card-title>
									<v-icon left>
										mdi-gamepad-circle-right
									</v-icon>
									<span>Manage Accounts </span>
								</v-card-title>
							</v-card>
						</v-col>
					</v-row>
				</v-col>

				<!-- Main Tabs -->
				<v-col cols="5">
					<v-tabs
						color="purple lighten-1"
						left
						v-model="mainTabs"
						background-color="transparent"
					>
						<v-tab>Home</v-tab>
						<v-tab>Posts</v-tab>
						<v-tab>Analytics</v-tab>
					</v-tabs>
				</v-col>

				<v-spacer></v-spacer>

				<v-col cols="4" class="d-flex justify-end align-center pa-4">
					<!-- New Post Button -->
					<v-btn
						rounded
						color="success"
						class="mr-4"
						@click.stop="showCreateNewPostModal = true"
					>
						<v-icon left> mdi-plus </v-icon>
						New Post
					</v-btn>

					<!-- Settings Button -->
					<v-btn color="primary" fab small>
						<v-icon>mdi-cog</v-icon>
					</v-btn>
				</v-col>
			</v-row>

			<v-divider></v-divider>

			<v-row no-gutters>
				<v-col cols="3">
					<v-row no-gutters>
						<v-col>
							<v-tabs vertical v-model="socialAccountTabs">
								<v-tab
									v-for="(
										facebookPage, index
									) in facebookPages"
									:key="index"
									class="my-2"
									style="height: 100%"
								>
									<v-card width="100%">
										<v-card-title>
											<v-icon x-large left color="blue">
												mdi-facebook
											</v-icon>
											<span
												class="
													text-subtitle-2
													text-capitalize
												"
											>
												{{ facebookPage.name }}
											</span>
										</v-card-title>
									</v-card>
								</v-tab>
							</v-tabs>
						</v-col>
					</v-row>
				</v-col>

				<v-col cols="9">
					<v-tabs-items
						v-model="mainTabs"
						transition="fade-transition"
						reverse-transition="fade-transition"
					>
						<!-- Home Content -->
						<v-tab-item
							transition="fade-transition"
							reverse-transition="fade-transition"
							v-if="facebookPages.length === 0"
						>
							<NoData />
						</v-tab-item>
						<v-tab-item
							v-else
							transition="fade-transition"
							reverse-transition="fade-transition"
						>
							<v-tabs-items
								v-model="socialAccountTabs"
								transition="fade-transition"
								reverse-transition="fade-transition"
							>
								<v-tab-item
									v-for="(
										facebookPage, index
									) in facebookPages"
									:key="index"
									transition="fade-transition"
									reverse-transition="fade-transition"
								>
									<h3>Page Name: {{ facebookPage.name }}</h3>
									<h4>
										Category: {{ facebookPage.category }}
									</h4>
								</v-tab-item>
							</v-tabs-items>
						</v-tab-item>

						<!-- Post Content -->
						<v-tab-item
							transition="fade-transition"
							reverse-transition="fade-transition"
							v-if="facebookPages.length === 0"
						>
							<NoData />
						</v-tab-item>
						<v-tab-item
							v-else
							transition="fade-transition"
							reverse-transition="fade-transition"
						>
							<v-tabs-items
								v-model="socialAccountTabs"
								transition="fade-transition"
								reverse-transition="fade-transition"
							>
								<v-tab-item
									v-for="(
										facebookPage, index
									) in facebookPages"
									:key="index"
									transition="fade-transition"
									reverse-transition="fade-transition"
								>
									<ListOfPosts
										ref="listOfPostsComponent"
										:facebook-page="facebookPage"
										:facebook-account="facebookAccount"
									/>
								</v-tab-item>
							</v-tabs-items>
						</v-tab-item>

						<!-- Analytics Content -->
						<v-tab-item
							transition="fade-transition"
							reverse-transition="fade-transition"
							v-if="facebookPages.length === 0"
						>
							<NoData />
						</v-tab-item>
						<v-tab-item
							v-else
							transition="fade-transition"
							reverse-transition="fade-transition"
						>
							<v-tabs-items
								v-model="socialAccountTabs"
								transition="fade-transition"
								reverse-transition="fade-transition"
							>
								<v-tab-item
									v-for="(
										facebookPage, index
									) in facebookPages"
									:key="index"
									transition="fade-transition"
									reverse-transition="fade-transition"
								>
									<p>Page ID: {{ facebookPage.pageId }}</p>
								</v-tab-item>
							</v-tabs-items>
						</v-tab-item>
					</v-tabs-items>
				</v-col>
			</v-row>

			<CreateNewPostModal
				v-model="showCreateNewPostModal"
				:facebook-pages="facebookPages"
				:facebook-account="facebookAccount"
				@home-refresh-post-tabs="homeRefreshPostTabs"
			/>

			<AddSocialAccountModal v-model="showAddSocialAccountModal" />
		</v-card>
	</v-container>
</template>

<script>
import CreateNewPostModal from '../components/CreateNewPostModal'
import AddSocialAccountModal from '../components/AddSocialAccountModal'
import ListOfPosts from '../components/ListOfPosts'
import NoData from '../../../components/NoData'

export default {
	components: {
		CreateNewPostModal,
		AddSocialAccountModal,
		ListOfPosts,
		NoData,
	},
	methods: {
		homeRefreshPostTabs() {
			this.$nextTick(function () {
				this.$refs.listOfPostsComponent.refreshAllPostTabs()
			})
		},
		changeBrand(brandId, brandName) {
			console.log(brandName)
			this.$router.push({ path: this.items.path })
			const projectId = this.$store.state.app.projectId
			this.$router.push({
				path: `/p/${projectId}/dashboard/social/brands/${brandId}`,
				// name: this.$router.currentRoute.name,
			})
		},
	},
	created() {
		this.$store.dispatch('socialMedia/facebook/getSocialAccounts', {
			payload: this.$route.params,
		})
	},
	computed: {
		selectedBrand: {
			get: function () {
				return this.$store.state.socialMedia.brands[0]
			},
			set: function (newValue) {
				return (this.selectedBrand = newValue)
			},
		},
		brandItems() {
			return this.$store.getters['socialMedia/brands/getBrands']
		},
		facebookPages() {
			return this.$store.getters['socialMedia/facebook/getFacebookPages']
		},
		facebookAccount() {
			return this.$store.getters[
				'socialMedia/facebook/getFacebookAccount'
			]
		},
	},
	data() {
		return {
			mainTabs: null,
			socialAccountTabs: null,
			showCreateNewPostModal: false,
			showAddSocialAccountModal: false,
		}
	},
}
</script>

<style>
.container {
	height: 100%;
}
.v-card.home {
	height: 100%;
}
.v-card {
	margin: 0px !important;
}
.v-list-item {
	padding: 6px 16px;
}
.v-list-item img {
	padding-right: 6px;
}
.v-select__selections img {
	padding-right: 6px !important;
}
/* Brand Dropdown Menu */
.brand-dropdown .v-text-field__details {
	display: none;
}
.brand-dropdown .v-input__slot {
	margin-bottom: 0px;
}
.brand-dropdown .v-input__slot {
	min-height: 66px !important;
}

.v-tabs-bar {
	height: 68px;
}
</style>
