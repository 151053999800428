<template>
	<div>
		<v-tabs
			background-color="grey lighten-4 dark"
			color="purple lighten-1"
			v-model="tab"
		>
			<v-tab>Published Posts</v-tab>
			<v-tab>Scheduled Posts</v-tab>
			<v-tab>Drafts</v-tab>
			<v-tab>Calendar</v-tab>

			<v-btn
				elevation="0"
				small
				fab
				class="mt-3"
				@click="refreshAllPostTabs()"
			>
				<v-icon color="grey darken-1">mdi-refresh</v-icon>
			</v-btn>
		</v-tabs>

		<v-tabs-items v-model="tab">
			<!-- Published Posts -->
			<v-tab-item
				transition="fade-transition"
				reverse-transition="fade-transition"
			>
				<v-data-table
					:headers="publishedPostsHeaders"
					:items="publishedPosts"
					:items-per-page="10"
					:loading="loadingPublishedPosts"
					loading-text="Loading... Please wait"
				>
					<template v-slot:[`item.link`]="{ item }">
						<a :href="item.link" target="_blank">
							<v-icon left> mdi-link </v-icon>
						</a>
					</template>
					<template v-slot:[`item.picture`]="{ item }">
						<v-img
							max-height="50"
							max-width="100"
							:src="item.picture"
						></v-img>
					</template>
					<template v-slot:[`item.status`]="{ item }">
						<v-chip color="green" dark>
							{{ item.status }}
						</v-chip>
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<!-- <v-icon
							small
							class="mr-2"
							@click="editPublishedPost(item.id)"
						>
							mdi-pencil
						</v-icon> -->
						<v-icon
							small
							class="ml-2"
							@click="deletePublishedPost(item.id)"
						>
							mdi-delete
						</v-icon>
					</template>
				</v-data-table>
			</v-tab-item>

			<!-- Scheduled Posts -->
			<v-tab-item
				transition="fade-transition"
				reverse-transition="fade-transition"
			>
				<v-data-table
					:headers="scheduledPostsHeaders"
					:items="scheduledPosts"
					:items-per-page="5"
					:loading="loadingScheduledPosts"
					loading-text="Loading... Please wait"
				>
					<template v-slot:[`item.link`]="{ item }">
						<a :href="item.link" target="_blank">
							<v-icon left> mdi-link </v-icon>
						</a>
					</template>

					<template v-slot:[`item.picture`]="{ item }">
						<v-img
							max-height="50"
							max-width="100"
							:src="item.picture"
						></v-img>
					</template>

					<template v-slot:[`item.status`]="{ item }">
						<v-chip color="blue" dark>
							{{ item.status }}
						</v-chip>
					</template>

					<template v-slot:[`item.actions`]="{ item }">
						<v-icon
							small
							color="blue"
							@click="editScheduledPost(item.id)"
						>
							mdi-pencil
						</v-icon>
						<v-icon
							small
							color="red"
							class="ml-2"
							@click="deleteScheduledPost(item.id)"
						>
							mdi-delete
						</v-icon>
					</template>
				</v-data-table>
			</v-tab-item>

			<!-- Drafts -->
			<v-tab-item
				transition="fade-transition"
				reverse-transition="fade-transition"
			>
				<p>Drafts</p>
			</v-tab-item>

			<!-- Calendar -->
			<v-tab-item
				transition="fade-transition"
				reverse-transition="fade-transition"
			>
				<p>Calendar</p>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import moment from 'moment'
export default {
	props: {
		facebookAccount: {
			type: Object,
			required: false,
		},
		facebookPage: {
			type: Object,
			required: false,
		},
	},
	data() {
		return {
			pageData: {
				id: '',
				name: '',
				category: '',
				token: '',
			},
			pageId: '',
			pageToken: '',
			tab: null,
			publishedPosts: [],
			loadingPublishedPosts: false,
			publishedPostsHeaders: [
				{
					text: 'Published on',
					value: 'publishedOn',
					align: 'start',
					sortable: false,
				},
				{ text: 'Content', value: 'content', sortable: false },
				{ text: 'Picture', value: 'picture', sortable: false },
				{ text: 'Link', value: 'link', sortable: false },
				{ text: 'Status', value: 'status', sortable: false },
				{ text: 'Actions', value: 'actions', sortable: false },
			],
			scheduledPosts: [],
			loadingScheduledPosts: false,
			scheduledPostsHeaders: [
				{
					text: 'Scheduled on',
					value: 'scheduledOn',
					align: 'start',
					sortable: false,
				},
				{ text: 'Content', value: 'content', sortable: false },
				{ text: 'Picture', value: 'picture', sortable: false },
				{ text: 'Link', value: 'link', sortable: false },
				{ text: 'Status', value: 'status', sortable: false },
				{ text: 'Actions', value: 'actions', sortable: false },
			],
		}
	},
	mounted() {
		this.getPublishedPosts()
		this.getScheduledPosts()
	},
	methods: {
		// General
		refreshAllPostTabs() {
			this.getPublishedPosts()
			this.refreshScheduledPostsList()
		},
		// Methods for Published Posts
		getPublishedPosts() {
			this.loadingPublishedPosts = true

			const projectId = this.$store.state.app.projectId
			const brandId = this.$route.params.id
			const pageId = this.facebookPage.pageId
			const userId = this.facebookAccount.userId

			this.axios
				.get(
					`/${projectId}/social/brands/${brandId}/facebook/posts?projectId=${projectId}&brandId=${brandId}&userId=${userId}&pageId=${pageId}`
				)
				.then((res) => {
					console.log(res)
					const posts = res.data.posts
					this.publishedPosts = posts.map(this.constructPublishedPost)
					this.loadingPublishedPosts = false
				})
				.catch((err) => {
					console.log(err)
					this.loadingPublishedPosts = false
				})
		},
		editPublishedPost(id) {
			this.$router.push({ name: 'tutorial-details', params: { id: id } })
		},
		deletePublishedPost(id) {
			const projectId = this.$store.state.app.projectId
			const brandId = this.$route.params.id
			const pageId = this.facebookPage.pageId
			const userId = this.facebookAccount.userId
			const postId = id

			this.loadingPublishedPosts = true

			this.axios
				.delete(
					`/${projectId}/social/brands/${brandId}/facebook/posts/${postId}?projectId=${projectId}&brandId=${brandId}&userId=${userId}&pageId=${pageId}&postId=${postId}`
				)
				.then((res) => {
					this.$snackbar.notify({ message: res.data.message })
					this.loadingPublishedPosts = false
					this.getPublishedPosts()
				})
				.catch((err) => {
					this.loadingPublishedPosts = false
					console.error(err.response.data)
				})
		},
		constructPublishedPost(post) {
			return {
				id: post.id,
				publishedOn: moment(post.created_time).format(
					'YYYY-MM-DD HH:mm:ss'
				),
				content:
					'message' in post
						? post.message.length > 100
							? post.message.substr(0, 100) + '...'
							: post.message
						: '--',
				picture: 'picture' in post ? post.picture : 'No image',
				link: post.permalink_url,
				status: post.is_published ? 'Published' : 'Pending',
			}
		},

		// Methods for Scheduled Posts
		getScheduledPosts() {
			this.loadingScheduledPosts = true
			this.axios
				.get(
					`https://graph.facebook.com/${this.facebookPage.pageId}/scheduled_posts?fields=created_time,id,message,call_to_action,permalink_url,likes,is_published,picture,full_picture,reactions,via,status_type&limit=10&access_token=${this.facebookPage.pageAccessToken}`
				)
				.then((response) => {
					console.log(response)
					const posts = response.data.data
					this.scheduledPosts = posts.map(this.constructScheduledPost)
					this.loadingScheduledPosts = false
				})
				.catch((e) => {
					console.log(e)
					this.loadingScheduledPosts = false
				})
		},
		refreshScheduledPostsList() {
			this.getScheduledPosts()
		},
		editScheduledPost(id) {
			this.$router.push({ name: 'tutorial-details', params: { id: id } })
		},
		deleteScheduledPost(id) {
			this.loadingScheduledPosts = true
			this.axios
				.delete(
					`https://graph.facebook.com/${id}?access_token=${this.pageToken}`
				)
				.then(() => {
					this.loadingScheduledPosts = false
					this.refreshScheduledPostsList()
				})
				.catch((e) => {
					this.loadingScheduledPosts = false
					console.log(e)
				})
		},
		constructScheduledPost(post) {
			return {
				id: post.id,
				scheduledOn: moment(post.created_time).format(
					'YYYY-MM-DD HH:mm:ss'
				),
				content:
					'message' in post
						? post.message.length > 100
							? post.message.substr(0, 100) + '...'
							: post.message
						: '--',
				picture: 'picture' in post ? post.picture : 'No image',
				link: post.permalink_url,
				status: post.is_published ? 'Published' : 'Pending',
			}
		},
	},
}
</script>
