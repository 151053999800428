<template>
	<v-row justify="center">
		<v-dialog v-model="show" max-width="800px">
			<v-card>
				<v-card-title>
					<v-row>
						<v-col>
							<p class="text-h5">Create New Post</p>
						</v-col>
					</v-row>
				</v-card-title>

				<v-card-text>
					<v-container>
						<v-select
							v-model="selectedSocialAccounts"
							:items="listOfSocialAccounts"
							item-text="name"
							return-object
							:menu-props="{ maxHeight: '400' }"
							class="mb-4"
							outlined
							label="Select social accounts"
							multiple
							hint="Select accounts to publish the post on"
							persistent-hint
						></v-select>

						<!-- Message -->
						<v-row class="mx-0">
							<v-col class="pa-0">
								<v-textarea
									class="mx-0"
									v-model="message"
									solo
									label="What's in your mind?"
								></v-textarea>
							</v-col>
						</v-row>

						<!-- Attachments -->
						<v-row class="mx-0">
							<v-col cols="1" class="pa-0">
								<v-file-input
									hide-input
									accept="image/*"
									label="Upload Image"
									filled
									prepend-icon="mdi-image-multiple"
								></v-file-input>
							</v-col>
							<v-col cols="1" class="pa-0">
								<v-file-input
									hide-input
									label="Upload Image"
									filled
								></v-file-input>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<FileUploader
									:initFiles="imageUploads"
									@errorOccured="dropzoneError"
									:purpose="`GMB`"
									:maxFiles="10"
									@changed="fileUploaderChanged"
								/>
							</v-col>
						</v-row>

						<!-- Schedule Post -->
						<v-row class="mx-0">
							<v-col cols="12" class="pa-0">
								<v-checkbox
									v-model="scheduledPost"
									label="Schedule for later"
								></v-checkbox>
							</v-col>

							<v-col
								cols="6"
								class="pa-0 pr-2 d-flex"
								v-if="scheduledPost"
							>
								<v-date-picker
									v-model="date"
									:min="minDate"
								></v-date-picker>
							</v-col>

							<v-col
								cols="6"
								class="pa-0 d-flex"
								v-if="scheduledPost && date"
							>
								<v-time-picker
									v-if="date"
									v-model="time"
									color="green lighten-1"
									header-color="blue"
									:min="minTime"
								></v-time-picker>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<!-- Actions -->
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						v-if="scheduledPost == true"
						color="green darken-1"
						text
						@click.stop="scheduleNewPost(selectedSocialAccounts)"
					>
						Schedule
					</v-btn>
					<v-btn
						v-if="scheduledPost == false"
						color="blue darken-1"
						text
						@click.stop="publishNewPostNow(selectedSocialAccounts)"
					>
						Post Now
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import FileUploader from '@/components/FileUploader'
import moment from 'moment'

export default {
	props: {
		value: Boolean,
		facebookPages: {
			type: Array,
			required: false,
		},
		facebookAccount: {
			type: Object,
			required: false,
		},
	},
	components: {
		FileUploader,
	},
	data() {
		return {
			listOfSocialAccounts: [],
			selectedSocialAccounts: [],
			minDate: moment(new Date()).format('YYYY-MM-DD'),
			scheduledPost: false,
			date: null,
			time: null,
			message: '',
			images: [],
			imageUploads: [],
		}
	},
	watch: {
		facebookPages: {
			immediate: true,
			handler() {
				this.listOfSocialAccounts = this.facebookPages.map((obj) => {
					return {
						name: `${obj.name} (Facebook Page)`,
						pageId: obj.pageId,
						userId: this.facebookAccount.userId,
					}
				})
			},
		},
	},
	computed: {
		show: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
		minTime() {
			if (this.date === moment(new Date()).format('YYYY-MM-DD')) {
				return moment(new Date()).format('HH:mm')
			}
			return '00:00'
		},
		scheduledTime() {
			return this.date + 'T' + this.time + ':00.000Z'
		},
	},
	methods: {
		// Image Upload
		fileUploaderChanged(val) {
			if (val.length != 0) {
				this.imageUploads = val
				this.images = val
			} else {
				this.imageUploads = []
			}
		},
		dropzoneError(val) {
			console.log(val)
		},
		// Facebook
		publishNewPostNow(selectedSocialAccounts) {
			if (selectedSocialAccounts.length === 0) {
				this.$snackbar.notify({
					message: 'Please select at least one social account',
				})
			} else {
				selectedSocialAccounts.forEach((item) => {
					if (this.imageUploads.length > 0) {
						this.images = this.imageUploads.map((obj) => ({
							sourceUrl: obj.publicURL,
						}))
					}
					const projectId = this.$store.state.app.projectId
					const brandId = this.$route.params.id

					const payload = {
						projectId,
						brandId,
						userId: item.userId,
						pageId: item.pageId,
						message: this.message,
						images: this.images,
					}
					console.log(payload)
					this.axios
						.post(
							`/${projectId}/social/brands/${brandId}/facebook/posts`,
							payload
						)
						.then((res) => {
							this.show = false
							this.message = ''
							this.images = []
							this.$snackbar.notify({
								message: 'Published Successfully',
								color: 'success',
							})
							this.$emit('home-refresh-post-tabs')
							console.log(res.status)
						})
						.catch((err) => {
							this.show = false
							this.$snackbar.notify({
								message: 'Failed to publish. Please try again.',
								color: 'error',
							})
							console.log(err)
						})
				})
			}
		},
		scheduleNewPost(selectedSocialAccounts) {
			if (selectedSocialAccounts.length === 0) {
				this.$snackbar.notify({
					message: 'Please select at least one social account',
				})
			} else {
				selectedSocialAccounts.forEach((item) => {
					if (this.imageUploads.length > 0) {
						this.images = this.imageUploads.map((obj) => ({
							sourceUrl: obj.publicURL,
						}))
					}
					const projectId = this.$store.state.app.projectId
					const brandId = this.$route.params.id

					const payload = {
						projectId,
						brandId,
						userId: item.userId,
						pageId: item.pageId,
						facebookAccount: this.facebookAccount.id,
						scheduledTime: this.scheduledTime,
						message: this.message,
						images: this.images,
					}

					console.log(payload)

					this.axios
						.post(
							`/${projectId}/social/brands/${brandId}/facebook/posts/schedule`,
							payload
						)
						.then((res) => {
							this.show = false
							this.message = ''
							this.scheduledPost = false
							this.date = null
							this.time = null
							this.$snackbar.notify({
								message: 'Post scheduled successfully',
								color: 'success',
							})
							this.$emit('home-refresh-post-tabs')
							console.log(res.status)
						})
						.catch((e) => {
							this.show = false
							this.scheduledPost = false
							this.date = null
							this.time = null
							this.$snackbar.notify({
								message:
									'Failed to schedule post. Please try again.',
								color: 'error',
							})
							console.log(e)
						})
				})
			}
		},
	},
}
</script>
